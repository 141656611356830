import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Work() {
  return (
    <div id="Work" className="container">

      <div className="work">
        <div id="bg2"></div>
        <div className="content-wrapper">
          <h1> <i><FontAwesomeIcon icon="id-card" className="hide_on_small"/></i> Work Experience <i><FontAwesomeIcon icon="id-card" className="hide_on_small" /></i></h1>
          
          <div className="headerLine">
            <div className="thickLine"></div>
            <div className="slimLine"></div>
          </div>

          <div className="exCardWrapper">

            <ul>
              <li>
                <h2>MediaLab</h2>
                <h3>AI & Data Solutions Manager</h3>
                <h4>Lawrenceville, GA, USA</h4>
                <p>May 2022 - Present</p>
              </li>
              <li>
                <h2>University of Georgia</h2>
                <h3>Instructor | Graduate Assistant</h3>
                <h4>Athens, GA, USA</h4>
                <p>August 2020 - June 2022</p>
              </li>
              <li>
                <h2>Georgia Oral and Facial Reconstructive Surgery</h2>
                <h3>Software Engineer</h3>
                <h4>Marietta, GA, USA</h4>
                <p>May 2020 - August 2021</p>
              </li>
              <li>
                <h2>University of Georgia</h2>
                <h3>Instructor</h3>
                <h4>Athens, GA, USA</h4>
                <p>January 2020 - May 2020</p>
              </li>
              
              <li>
                <h2>University of Georgia</h2>
                <h3>Graduate Assistant</h3>
                <h4>Athens, GA, USA</h4>
                <p>August 2018 - January 2020</p>
              </li>
              <li>
                <h2>Gametic</h2>
                <h3>Software Engineer</h3>
                <h4>Tehran, Tehran, Iran</h4>
                <p>September 2016 – August 2018</p>
              </li>
              <li>
                <h2>Cognitive Science Student Association (CSSA)</h2>
                <h3>Core Member | Full Stack Web Developer</h3>
                <h4>Tehran, Tehran, Iran</h4>
                <p>January 2016 – July 2017</p>
              </li>
              <li>
                <h2>IUST ACM Judge</h2>
                <h3>Software Engineer</h3>
                <h4>Tehran, Tehran, Iran</h4>
                <p>September 2016 – August 2017</p>
              </li>
              <li>
                <h2>Myket</h2>
                <h3>Frontend Software Engineer</h3>
                <h4>Tehran, Tehran, Iran</h4>
                <p>June 2016 – September 2016</p>
              </li>
              <li>
                <h2>IrsaPardaz</h2>
                <h3>Backend Software Engineer</h3>
                <h4>Tehran, Tehran, Iran</h4>
                <p>September 2015 – January 2016</p>
              </li>
            </ul>
            
          </div>    
        
        </div>
      </div>
    </div>
  )
}

export default Work;
